import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        <br></br>
        <p>
          Guess the word in six tries. After each guess, the color of the tiles
          change to show how close your guess was to the word.
        </p>
        <br></br>
        <p>
          There are three modes(Settings Icon) to play:
          <ul>
            <li>Clue Mode(Reveal Vowel Clues in the word)</li>

            <li>Five Mode(Five or Four letter word)</li>
            <li>Calendar Mode(Word of the day or unlimited words)</li>
          </ul>
        </p>

        <br></br>
        <p>
          Puzzle Icon gives word of the day or unlimited words based on the
          Calendar Mode. It will be Five or Four letter word based on the Five
          Mode. Key Icon will reveal the solution anytime.
        </p>
        <br></br>
        <p>
          Tip: You don&#39;t have to wait a whole day for new words, just turn
          off Calendar mode. You can make you&#39;re solving as challenging or
          less at any point by turning on or off the Clue Mode.
        </p>
        <br></br>
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="?"
          status="correct"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="?"
          status="correct"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="E"
          status="correct"
        />

        <Cell
          isRevealing={true}
          isCompleted={true}
          value="?"
          status="correct"
        />

        <Cell
          isRevealing={true}
          isCompleted={true}
          value="?"
          status="correct"
        />
      </div>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        The Vowels in the word are given as a Clue.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="W"
          status="correct"
        />
        <Cell value="E" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell value="Y" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter W is in the word and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="P" isCompleted={true} />
        <Cell value="I" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="present"
        />
        <Cell value="O" isCompleted={true} />
        <Cell value="T" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="V" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="G" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="U" status="absent" />
        <Cell value="E" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is not in the word in any spot.
      </p>
    </BaseModal>
  )
}
