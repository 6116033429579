import './App.css'

import { ClockIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'

import { AlertContainer } from './components/alerts/AlertContainer'
import { Clue } from './components/clue/Clue'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { Navbar } from './components/navbar/Navbar'
import {
  DATE_LOCALE,
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  //CALENDAR_MODE_ALERT_MESSAGE,
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  FIVE_MODE_ALERT_MESSAGE,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
  WIN_MESSAGES,
  WORD_NOT_FOUND_MESSAGE,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { isInAppBrowser } from './lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import { getGuessStatuses } from './lib/statuses'
import { vowelsInSolution } from './lib/words'
import {
  findFirstUnusedReveal,
  getGameDate,
  getIsLatestGame,
  getVowelsInSolution,
  isWinningWord,
  isWordInWordList,
  setGameDate,
  setVowelsInSolution,
  solution,
  solutionGameDate,
  unicodeLength,
} from './lib/words'

function App() {
  const originalVowelWord = getVowelsInSolution()
  const isLatestGame = getIsLatestGame()
  const gameDate = getGameDate()
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  //const [vowelsInSolution, setVowelsInSolution] = useState('')
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isKeyRevealed, setIsKeyRevealed] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const revealSolution = () => {
    setIsKeyRevealed(true)
    showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
      persist: false,
    })
  }

  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES) {
      showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
        persist: false,
      })
      setIsGameLost(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  const [isFiveMode, setIsFiveMode] = useState(
    localStorage.getItem('letterMode')
      ? localStorage.getItem('letterMode') === 'five'
      : false
  )

  const [isClueMode, setIsClueMode] = useState(
    localStorage.getItem('clueMode')
      ? localStorage.getItem('clueMode') === 'clue'
      : false
  )

  const [isCalendarMode, setIsCalendarMode] = useState(
    localStorage.getItem('calendarMode')
      ? localStorage.getItem('calendarMode') === 'calendar'
      : false
  )

  useEffect(() => {
    // if no game state on load,
    document.title = 'SolveWords'
  })

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage(true)) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleFiveMode = (isFive: boolean) => {
    //if (guesses.length === 0 || localStorage.getItem('letterMode') === 'five') {
    if (guesses.length === MAX_CHALLENGES || isGameWon || isKeyRevealed) {
      setIsFiveMode(isFive)
      localStorage.setItem('letterMode', isFive ? 'five' : 'four')
    } else {
      showErrorAlert(FIVE_MODE_ALERT_MESSAGE)
    }
  }

  const handleClueMode = (isClue: boolean) => {
    setIsClueMode(isClue)
    localStorage.setItem('clueMode', isClue ? 'clue' : 'none')
  }

  const handleCalendarMode = (isCalendar: boolean) => {
    //if (
    //guesses.length === 0 ||
    //localStorage.getItem('calendarMode') === 'calendar'
    //guesses.length === MAX_CHALLENGES ||
    //isGameWon
    //) {
    setIsCalendarMode(isCalendar)
    localStorage.setItem('calendarMode', isCalendar ? 'calendar' : 'random')
    //} else {
    //showErrorAlert(CALENDAR_MODE_ALERT_MESSAGE)
    //}
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * solution.length

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      })
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, (solution.length + 1) * REVEAL_TIME_MS)
    }
  }, [isGameWon, isGameLost, showSuccessAlert])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  var wordLength: number
  //var calendarModeSetting: number
  const onEnter = () => {
    //console.log('isGameWon' + isGameWon)
    //console.log('isGameLost' + isGameLost)
    wordLength = localStorage.getItem('letterMode') === 'five' ? 5 : 4
    //calendarModeSetting =
    //localStorage.getItem('calendarMode') === 'calendar' ? 1 : 0

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * solution.length)

    const winningWord = isWinningWord(currentGuess)

    if (
      unicodeLength(currentGuess) === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        //New code
        const statuses = getGuessStatuses(solution, currentGuess)

        //console.log('Vowels In solution: ' + vowelsInSolution)
        //console.log('Solution: ' + solution)
        //console.log('Current Statuses: ' + currentGuess)
        //console.log('Statuses: ' + statuses)

        let enterVowelsText2 = ''
        // Populate initial state of "tries".
        for (let j = 0; j < wordLength; j++) {
          if (statuses[j] === 'correct') {
            enterVowelsText2 = enterVowelsText2 + currentGuess[j]
          } else {
            enterVowelsText2 = enterVowelsText2 + originalVowelWord[j]
          }
        }

        //console.log('Vowels in Situvation' + vowelsInSolution)
        //console.log('enterVowelsText: ' + enterVowelsText2)
        //setVowelsInSolution(enterVowelsText2)
        setVowelsInSolution(enterVowelsText2)
        //console.log('Vowels in Situvation AFTER SET' + vowelsInSolution)

        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })
        if (isLatestGame) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        setVowelsInSolution(solution)
        return setIsGameLost(true)
        /*showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })*/
      }
    }

    const statuses = getGuessStatuses(solution, currentGuess)

    // console.log('Vowels In solution: ' + vowelsInSolution)
    //console.log('Solution: ' + solution)
    //console.log('Current Statuses: ' + currentGuess)
    //console.log('Statuses: ' + statuses)

    let enterVowelsText2 = ''
    // Populate initial state of "tries".
    for (let j = 0; j < wordLength; j++) {
      if (statuses[j] === 'correct') {
        enterVowelsText2 = enterVowelsText2 + currentGuess[j]
      } else {
        enterVowelsText2 = enterVowelsText2 + originalVowelWord[j]
      }
    }

    //console.log('Vowels in Situvation' + vowelsInSolution)
    //console.log('enterVowelsText: ' + enterVowelsText2)
    //setVowelsInSolution(enterVowelsText2)
    setVowelsInSolution(enterVowelsText2)
    //console.log('Vowels in Situvation AFTER SET' + vowelsInSolution)
  }

  return (
    <Div100vh>
      <div className="flex h-full flex-col">
        <Navbar
          setIsInfoModalOpen={setIsInfoModalOpen}
          setIsStatsModalOpen={setIsStatsModalOpen}
          setIsDatePickerModalOpen={setIsDatePickerModalOpen}
          setIsSettingsModalOpen={setIsSettingsModalOpen}
          revealSolution={revealSolution}
        />

        {!isLatestGame && (
          <div className="flex items-center justify-center">
            <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
            <p className="text-base text-gray-600 dark:text-gray-300">
              {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
            </p>
          </div>
        )}

        <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
          <div className="flex grow flex-col justify-center pb-6 short:pb-2">
            {isClueMode && (
              <Clue
                solution={vowelsInSolution}
                guesses={[vowelsInSolution]}
                currentGuess={vowelsInSolution}
                isRevealing={isRevealing}
                currentRowClassName={currentRowClass}
              />
            )}

            <Grid
              solution={solution}
              guesses={guesses}
              currentGuess={currentGuess}
              isRevealing={isRevealing}
              currentRowClassName={currentRowClass}
            />
          </div>
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            solution={solution}
            guesses={guesses}
            isRevealing={isRevealing}
          />
          <InfoModal
            isOpen={isInfoModalOpen}
            handleClose={() => setIsInfoModalOpen(false)}
          />
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            solution={solution}
            guesses={guesses}
            gameStats={stats}
            isLatestGame={isLatestGame}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
            handleShareFailure={() =>
              showErrorAlert(SHARE_FAILURE_TEXT, {
                durationMs: LONG_ALERT_TIME_MS,
              })
            }
            handleMigrateStatsButton={() => {
              setIsStatsModalOpen(false)
              setIsMigrateStatsModalOpen(true)
            }}
            isHardMode={isHardMode}
            isDarkMode={isDarkMode}
            isHighContrastMode={isHighContrastMode}
            numberOfGuessesMade={guesses.length}
          />
          <DatePickerModal
            isOpen={isDatePickerModalOpen}
            initialDate={solutionGameDate}
            handleSelectDate={(d) => {
              setIsDatePickerModalOpen(false)
              setGameDate(d)
            }}
            handleClose={() => setIsDatePickerModalOpen(false)}
          />
          <MigrateStatsModal
            isOpen={isMigrateStatsModalOpen}
            handleClose={() => setIsMigrateStatsModalOpen(false)}
          />
          <SettingsModal
            isOpen={isSettingsModalOpen}
            handleClose={() => setIsSettingsModalOpen(false)}
            isHardMode={isHardMode}
            handleHardMode={handleHardMode}
            isDarkMode={isDarkMode}
            handleDarkMode={handleDarkMode}
            isHighContrastMode={isHighContrastMode}
            handleHighContrastMode={handleHighContrastMode}
            isFiveMode={isFiveMode}
            handleFiveMode={handleFiveMode}
            isCalendarMode={isCalendarMode}
            handleCalendarMode={handleCalendarMode}
            isClueMode={isClueMode}
            handleClueMode={handleClueMode}
          />
          <AlertContainer />
        </div>
      </div>
    </Div100vh>
  )
}

export default App
