import {
  CALENDAR_MODE_DESCRIPTION,
  CLUE_MODE_DESCRIPTION,
  FIVE_MODE_DESCRIPTION,
  HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'
import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
  isFiveMode: boolean
  handleFiveMode: Function
  isCalendarMode: boolean
  handleCalendarMode: Function
  isClueMode: boolean
  handleClueMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
  isFiveMode,
  handleFiveMode,
  isCalendarMode,
  handleCalendarMode,
  isClueMode,
  handleClueMode,
}: Props) => {
  return (
    <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        <SettingsToggle
          settingName="Hard Mode"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Dark Mode"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="High Contrast Mode"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Five Mode"
          flag={isFiveMode}
          handleFlag={handleFiveMode}
          description={FIVE_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Calendar Mode"
          flag={isCalendarMode}
          handleFlag={handleCalendarMode}
          description={CALENDAR_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Clue Mode"
          flag={isClueMode}
          handleFlag={handleClueMode}
          description={CLUE_MODE_DESCRIPTION}
        />
      </div>
    </BaseModal>
  )
}
